<template>
  <svg
    width="16"
    height="10"
    viewBox="0 0 16 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.99996 3C8.53039 3 9.0391 3.21071 9.41417 3.58579C9.78925 3.96086 9.99996 4.46957 9.99996 5C9.99996 5.53043 9.78925 6.03914 9.41417 6.41421C9.0391 6.78929 8.53039 7 7.99996 7C7.46953 7 6.96082 6.78929 6.58575 6.41421C6.21067 6.03914 5.99996 5.53043 5.99996 5C5.99996 4.46957 6.21067 3.96086 6.58575 3.58579C6.96082 3.21071 7.46953 3 7.99996 3ZM7.99996 0C11.3333 0 14.18 2.07333 15.3333 5C14.18 7.92667 11.3333 10 7.99996 10C4.66663 10 1.81996 7.92667 0.666626 5C1.81996 2.07333 4.66663 0 7.99996 0ZM2.11996 5C2.6588 6.1002 3.4955 7.02717 4.53495 7.6755C5.57439 8.32384 6.77489 8.66755 7.99996 8.66755C9.22503 8.66755 10.4255 8.32384 11.465 7.6755C12.5044 7.02717 13.3411 6.1002 13.88 5C13.3411 3.8998 12.5044 2.97283 11.465 2.3245C10.4255 1.67616 9.22503 1.33245 7.99996 1.33245C6.77489 1.33245 5.57439 1.67616 4.53495 2.3245C3.4955 2.97283 2.6588 3.8998 2.11996 5Z"
      fill="black"
    />
  </svg>
</template>
